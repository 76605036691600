<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">
                            {{ $t('system.overduePenalty') }}
                        </h4>
                    </div>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('overduePenalty')"
                    >
                        {{ errors.first('overduePenalty') }}
                    </div>
                </div>
                <div
                    class="tw-mt-2  tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-72 tw-overflow-auto"
                >
                    <ts-table :records="resources">
                        <template v-slot:thead>
                            <thead>
                                <tr>
                                    <th class="tw-text-center">
                                        {{ $t('system.overdueFrom') }}
                                    </th>
                                    <th class="tw-text-center">
                                        {{ $t('system.overdueTo') }}
                                    </th>
                                    <th class="tw-text-center">
                                        {{ $t('system.penaltyRate') }}
                                    </th>
                                    <th class="tw-text-center">
                                        <a href="#" class="text-primary">
                                            <i
                                                class="fa fa-plus"
                                                @click.prevent="addNewRow"
                                            ></i>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot="{ record }">
                            <td width="30%" class="tw-text-center">
                                <input
                                    v-model.number="record.overdue_from"
                                    type="number"
                                    class="form-control tw-text-center"
                                />
                            </td>
                            <td width="30%" class="tw-text-center">
                                <input
                                    v-model.number="record.overdue_to"
                                    type="number"
                                    class="form-control tw-text-center"
                                />
                            </td>
                            <td width="30%" class="tw-text-center">
                                <InputNumber
                                    :max="100"
                                    v-model.number="record.penalty_rate"
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'penalty_rate'
                                        )
                                    }"
                                    :min="0"
                                ></InputNumber>
                            </td>
                            <td class="tw-text-center">
                                <a
                                    href="#"
                                    class="text-danger"
                                    @click.prevent="removeRow(record)"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </ts-table>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { clone } from 'lodash'

export default {
    name: 'overduePenalty',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {},
            newRow: {
                overdue_from: 0,
                overdue_to: 0,
                penalty_rate: 0
            }
        }
    },
    computed: {
        resources () {
            return this.model.overduePenalty
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        addNewRow () {
            this.model.overduePenalty.push(clone(this.newRow))
        },
        removeRow (item) {
            this.model.overduePenalty.splice(
                this.model.overduePenalty.indexOf(item),
                1
            )
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
<style lang="css" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    /* border-color: #e2e7eb; */
    padding: 4px 2px !important;
    vertical-align: middle;
}
.table > :not(:first-child) {
    border-top: 1px solid grey;
}
</style>
